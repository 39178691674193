@import '../../../../src/assets/scss/resources';

.main {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: $width-d;
  width: 100%;
  margin: 0 auto;
  align-items: center;

  @include media(lg) {
    margin: 60px auto;
    flex-direction: row;
    align-items: flex-start;
  }
}
.filter {
  flex-shrink: 0;
  @include media(lg) {
    margin-left: 15px;
  }
}
.list {
  height: calc(100vh - 200px) !important;

  @include media(lg) {
    height: auto !important;
  }
}

.header {
  position: fixed !important;
}

.contain {
  height: calc(100% - 54px);
  margin-top: 54px;

  @include media(lg) {
    height: calc(100% - 76px);
    margin-top: 76px;
  }
  overflow: auto;
}
