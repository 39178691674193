@import "../../../../src/assets/scss/resources";


.wrapper {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100 !important;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.3);
  text-align: center;
}
.container {
  background-color: white;
  margin: auto;
  margin-top: 100px;
  border-radius: 15px;
  padding: 30px;
  width: 470px;
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.title {
  font-size: 24px;
}
.count {
  font-size: 20px;
  text-align: right;
  color: $primaryColor1;
}
.text {
  text-align: left;
  padding-bottom: 14px;
   h1 {
    font-size: 24px;
    font-weight: 500;
   }
   h2 {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 10px;
   }
   li {
    margin-bottom: 5px;
   }
   ul {
    padding-left: 15px;
    margin-bottom: 24px;
   }
   p, li {
    font-size: 14px;
   }
   strong {
    font-weight: 700;
    color: $primaryColor1;
   }
   p {
    color: $silverColor;
    margin-bottom: 24px !important;
   }
  }
.buttons {
  display: flex;
  gap: 16px;
  width: 100%;
  justify-content: space-between;
}

.applyButton {
  max-width: none !important;
}

.cancelButton {
  background-color: transparent;
  min-width: 120px;
  cursor: pointer;
  border-radius:8px;
  height: 44px;
  border: 1px solid black;
}



