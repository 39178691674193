@import 'resources';


html {
  //font-size: 37.5%; // 1rem = 6px :: [6 / 16 = 37.5%]
  font-size: 62.5%; // 1rem = 10px

  @include media(xs) {
    //font-size: 37.5%; // 1rem = 6px
    font-size: 62.5%; // 1rem = 10px
  }
  @include media(sm) {
    //font-size: 43.75%; // 1rem = 7px
    font-size: 62.5%; // 1rem = 10px
  }
  @include media(md) {
    //font-size: 50%; // 1rem = 8px
    font-size: 62.5%; // 1rem = 10px
  }
  @include media(lg) {
    font-size: 62.5%; // 1rem = 10px
  }
  @include media(xl) {
    font-size: 62.5%; // 1rem = 10px
  }
}

@font-face {
  font-family: "AvenirNextCyr";
  font-weight: 200;
  src: url("../fonts/AvenirNextCyr-Thin.ttf")
}
@font-face {
  font-family: "AvenirNextCyr";
  font-weight: 300;
  src: url("../fonts/AvenirNextCyr-Light.ttf")
}
@font-face {
  font-family: "AvenirNextCyr";
  font-weight: 400;
  src: url("../fonts/AvenirNextCyr-Regular.ttf")
}
@font-face {
  font-family: "AvenirNextCyr";
  font-weight: 500;
  src: url("../fonts/AvenirNextCyr-Medium.ttf")
}
@font-face {
  font-family: "AvenirNextCyr";
  font-weight: 700;
  src: url("../fonts/AvenirNextCyr-Bold.ttf")
}
@font-face {
  font-family: "Inter";
  font-weight: 200;
  src: url("../fonts/Inter-Thin.ttf")
}
@font-face {
  font-family: "Inter";
  font-weight: 300;
  src: url("../fonts/Inter-Light.ttf")
}
@font-face {
  font-family: "Inter";
  font-weight: 400;
  src: url("../fonts/Inter-Regular.ttf")
}
@font-face {
  font-family: "Inter";
  font-weight: 500;
  src: url("../fonts/Inter-Medium.ttf")
}
@font-face {
  font-family: "Inter";
  font-weight: 700;
  src: url("../fonts/Inter-Bold.ttf")
}


*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  font-size: 14px;
  box-sizing: border-box;
  font-family: AvenirNextCyr, sans-serif;
}

p {
  margin: 0;
}

a {
  text-decoration: none;
}

h1{
  font-size: 21px;
}
h2{
  font-size: 18px;
}
h3{
  font-size: 14px;
}

input,
select,
textarea {
  -webkit-touch-callout: default; /* iOS Safari */
  -webkit-user-select: auto; /* Safari */
  -khtml-user-select: auto; /* Konqueror HTML */
  -moz-user-select: auto; /* Old versions of Firefox */
  -ms-user-select: auto; /* Internet Explorer/Edge */
  user-select: auto;
  outline: none;
}

*::-webkit-scrollbar-track {
  //-webkit-box-shadow: inset 0 0 2px rgba(245,85,50,0.27);
  border-radius: 0;
  background-color: #f5f5f5;
}

*::-webkit-scrollbar {
  width: 6px;
  background-color: rgba(244, 236, 245, 0.15);
}

*::-webkit-scrollbar-thumb {
  border-radius: 0;
  background-color: $primaryColor1;
}

*{
  scrollbar-color: $primaryColor1 #f5f5f5;
  scrollbar-width: thin;
}

#root {
  //height: 100vh;
  //overflow: hidden;
}

#modal-root {
  position: relative;
  z-index: 999;
  height: 80%;
}

#bg-root {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background: rgba(0, 0, 0, 0);
  transition: 0.5s;
  z-index: -1;
}

.bg-root--top {
  z-index: 999 !important;
}

.bg-root--visible {
  background: rgba(0, 0, 0, 0.75) !important;
}

//ul {
//  margin: 0 15px;
//}

a {
  color: $primaryColor1;
}

textarea {
  word-break: break-word;
}