@import "../../../../src/assets/scss/resources";

.root {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: $bg-color;
}

.content {
  position: relative;
  width: 100%;
  max-width: 380px;
  min-height: 180px;
  padding: 15px;
  background: #FFFFFF;
  border: 1px solid $borderColor;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  margin-top: 20px !important;
  margin-bottom: 0 !important;
}

.subtitle {

}

.modalContent {
  height: 320px !important;
  padding: 30px !important;
}

.modalTitle {
  margin-bottom: 30px !important;
  justify-content: center !important;
}

.langContent {
  overflow: auto;
  height: 100%;
}

.langList {

}

.langItem {
  font-size: 18px;
  font-weight: 400;
  color: $primaryColor2;
  cursor: pointer;
  padding: 15px 0;
}

.langItemActive {
  color: $primaryColor1;
}

.forgotPass {
  padding: 20px 30px 15px;
}

.login {
  max-width: none !important;
  margin: 30px 0 0;
}

.logout {
  max-width: none !important;
  margin: 30px 0 0;
}

.onboarding {
  width: 100%;
  margin: 30px 0;
}

.link {
  width: 100%;
}

.langPicker {
  position: absolute;
  top: 10px;
  right: 10px;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  color: $primaryColor2;
  padding: 5px 5px 2px;
  border-bottom: 1px solid $primaryColor1;
}