@import '../../../../src/assets/scss/resources';

.main {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: $width-d;
  width: 100%;
  height: calc(100vh - 54px);
  margin: 54px auto;
  align-items: center;

  @include media(lg) {
    height: calc(100vh - 166px);
    margin: 136px auto 30px;
    flex-direction: row;
    align-items: flex-start;
  }
}
.conversation {
  @include media(lg) {
    max-width: calc(100% - 420px);
    margin-left: 15px;
  }
}
