@import '../../../../src/assets/scss/resources';

.container {
  max-width: $width-d;
  width: 100%;
  margin: 30px auto 0;
  padding: 0 15px;

  @include media(lg) {
    margin: 60px auto 20px;
    padding: 0;
  }
}

.mainTitle {
  font-size: 32px !important;
  line-height: 32px !important;
  margin-bottom: 30px !important;
}

.header {
  position: fixed !important;
}

.contain {
  height: calc(100% - 54px);
  margin-top: 54px;

  @include media(lg) {
    height: calc(100% - 76px);
    margin-top: 76px;
  }
  overflow: auto;
}
