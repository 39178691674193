////////////////////////////////////////
// MEDIA QUERY MANAGER
////////////////////////////////////////

@mixin media($breakpoint) {
  @if $breakpoint == xs {
    @media only screen and (max-width: 374px) {
      @content;
    }
  }
  @if $breakpoint == sm {
    @media only screen and (min-width: 375px) and (max-width: 1023px) {
      @content;
    }
  }
  @if $breakpoint == md {
    @media only screen and (min-width: 1024px) and (max-width: 1279px) {
      @content;
    }
  }
  @if $breakpoint == lg {
    @media only screen and (min-width: 1280px) {
      @content;
    }
  }
}
