.page {
  height: 100%;
  padding: 30px;
}

.pageInner {
  overflow: auto;
  height: 100%;
  padding: 0 10px;

  *{
    h1{
      font-size: 21px !important;
    }
    h2{
      font-size: 18px !important;
    }
    h3{
      font-size: 15px !important;
    }
  }
}