@import '../../../../src/assets/scss/resources';

.root {
  position: relative;
  background: #f6f7f9;
  height: 100%;
  min-height: 100vh;
}

.withoutHeader {
  height: 54px;
  width: 100%;
  background-color: white;
  box-shadow: inset 0px -1px 0px rgba(145, 165, 182, 0.24);

  &Container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin: 0 auto;
    height: 100%;
    padding: 0 15px;

    @include media(lg) {
      width: $width-d;
      padding: 0;
    }
  }

  svg {
    margin-right: 15px;
  }

  @include media(lg) {
    height: 76px;
  }
}

.header {
  position: fixed !important;
}

.contain {
  height: calc(100% - 54px);
  margin-top: 54px;

  @include media(lg) {
    height: calc(100% - 76px);
    margin-top: 76px;
  }
  overflow: auto;
}

