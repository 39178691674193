@import '../../../../src/assets/scss/resources';

.container {
  background-color: rgba(0, 0, 0, 0.4);
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
}

.modal {
  position: relative;
  width: 100%;
  background: white;
  height: 100%;

  @include media(lg){
    width: 525px;
    max-height: 80%;
    border-radius: 15px;
  }

  &__closeButton {
    position: absolute;
    top: 25px;
    right: 25px;
    width: 24px;
    height: 24px;
    background: transparent;
    border: none;
    cursor: pointer;

    &:before,
    &:after {
      content: '';
      position: absolute;
      top: 50%;
      left: -3px;
      width: 30px;
      height: 1px;
      background: #505b6e;
    }

    &:before {
      transform: rotate(-45deg);
    }

    &:after {
      transform: rotate(45deg);
    }
  }
}

.wrapper{
  opacity: 0;
  visibility: hidden;

  &_visible {
    opacity: 1;
    visibility: visible;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
  }
}

@include media(sm) {
  .modal {
    &__closeButton {
      top: 20px;
      right: 20px;

      &:before,
      &:after {
        background: #4d495b;
        height: 2px;
        width: 20px;
        left: 50%;
      }

      &:before {
        transform: translateX(-50%) rotate(-45deg);
      }

      &:after {
        transform: translateX(-50%) rotate(45deg);
      }
    }
  }
}
