@import "../../../../src/assets/scss/resources";

.content {
  position: relative;
  padding: 30px 15px;
  width: 650px;

  @media screen and (max-width: 650px) {
    padding: 15px;
    width: 100vw !important;
  }
}

.close {
  position: absolute;
  top: 30px;
  right: 50px;
  cursor: pointer;
  width: 1px;
  height: 1px;
  &:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    display: flex;
    width: 30px;
    height: 3px;
    background: $primaryColor1;
    transform: rotate(45deg);
  }
  &:before {
    content: '';
    display: flex;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 30px;
    height: 3px;
    background: $primaryColor1;
    transform: rotate(-45deg);
  }
}