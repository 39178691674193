$primaryColor1: #F65532;
$primaryColor2: #282828;
$primaryColor1a03: rgba(246,85,50,0.03);
$primaryColor1a05: rgba(246,85,50,0.05);
$primaryColor1a10: rgba(246,85,50,0.10);
$primaryColor1a30: rgba(246,85,50,0.30);
$primaryColor2a05: rgba(40,40,40,0.05);
$secondaryColor1: #97A3AC;
$secondaryColor1a05: rgba(151,163,172,0.05);
$secondaryColor2: #ACB7BF;
$secondaryColor2a05: rgba(172, 183, 191, 0.05);
$defaultColor1: #F6F7F9;
$defaultColor1a05: rgba(246,247,249,0.05);
$textColor: #222222;
$outlineBtnBackground: #FFFFFF;
$notActiveBtnBackground: #F3F4F5;
$width-d: 1166px;
$bg-color: #F3F5F8;
$borderColor: #E3EAEF;
$silverColor: #656771;