@import '../../../../src/assets/scss/resources';

.root {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background: #f6f7f9;
  height: 100%;
  min-height: 100vh;
}

.header {
}

.container {
  margin: 30px auto 30px;
  width: 100%;
  max-width: 510px;
  padding: 0 15px;
  min-height: 460px;

  @include media(lg) {
    margin: 60px auto 60px;
  }
}

.content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  background-color: white;
  padding: 30px 0;
  border: 1px solid $borderColor;
  border-radius: 10px;
}

.head {
  width: 100%;
  padding: 0 15px;

  @include media(lg) {
    padding: 0 30px;
  }
}
.header {
  position: fixed !important;
}

// to do: use headerHeightM, headerHeightD, FooterHeight
.contain {
  height: calc(100% - 54px - 76px);
  margin-top: 54px;
  margin-bottom: 76px;

  @include media(lg) {
    height: calc(100% - 76px - 76px);
    margin-top: 76px;
    margin-bottom: 76px;
  }
  overflow: auto;
}
